/** @jsx jsx */
import { useState } from "react"
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"

import PageLayout from "../ui/page-layout"
import DropdownTabs from "../ui/dropdown-tabs"
import GreenTabs from "../ui/green-tabs"

import PrivacyPolicies from "./privacy-policies"
import RiskWarning from "./risk-warning"
import Transparency from "./transparency"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding-bottom: 5rem;
`

const dropdownTabsContainerCSS = css`
  display: none;
  @media (max-width: 736px) {
    display: block;
  }
`

const greenTabsContainerCSS = css`
  display: none;
  @media (min-width: 768px) {
    display: block;
    & button {
      padding: 12px 36px;
    }
  }
`

const tabs: TabType[] = [
  {
    text: "Póliticas de Privacidad",
    value: "privacy-policies",
  },
  {
    text: "Aviso Legal de Riesgo",
    value: "risk-warning",
  },
  {
    text: "Transparencia Corporativa",
    value: "transparency",
  },
]

const LegalPage = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  return (
    <PageLayout title="Legal">
      <Container>
        <DropdownTabs
          tabs={tabs}
          selectedTab={selectedTab}
          onClick={selectedTab => {
            setSelectedTab(selectedTab)
          }}
          containerCSS={dropdownTabsContainerCSS}
        />

        <GreenTabs
          tabs={tabs}
          selectedTab={selectedTab}
          onClick={selectedTab => {
            setSelectedTab(selectedTab)
          }}
          containerCSS={greenTabsContainerCSS}
        />

        {selectedTab.value === "privacy-policies" ? <PrivacyPolicies /> : null}
        {selectedTab.value === "risk-warning" ? <RiskWarning /> : null}
        {selectedTab.value === "transparency" ? <Transparency /> : null}
      </Container>
    </PageLayout>
  )
}

export default LegalPage
