import React from "react"
import styled from "@emotion/styled"

import AlerBanner from "../ui/alert-banner"
import OpenFileIcon from "../ui/images/open-file-icon"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 323px;
  margin-top: 30px;
  @media (min-width: 1024px) {
    margin-top: 60px;
    max-width: 663px;
  }
  @media (min-width: 736px) and (max-width: 1024px) {
    margin-top: 60px;
    max-width: 500px;
  }
`

const Title = styled.h1`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 116%;
`

const SubTitle = styled.h2`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #1f2425;
`

const Paragraph = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-size: 12px;
  line-height: 116%;
`

const Anchor = styled.a`
  display: flex;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130.5%;
  color: #000000;
  text-decoration: underline;
`

const MailTo = styled.a`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130.5%;
  color: #30ab76;
  text-decoration: underline;
`

const EthicLinkText = styled.span`
  margin-right: 5px;
`

const AlertWrapper = styled.div`
  padding: 1rem 0;
`

const Transparency = () => (
  <Container>
    <Content>
      <Title>Transparencia Corporativa</Title>

      <Paragraph>
        El departamento de Compliance del Grupo Capitaria tiene un rol
        fundamental en la implementación y monitoreo de los sistemas de
        cumplimiento ético, normativo y prevención de delitos.
      </Paragraph>

      <Paragraph>
        Es por ello que se han diseñado planes de acción con el objetivo de:
      </Paragraph>

      <Paragraph>
        1. Velar por el correcto cumplimiento normativo y regulatorio aplicable
        a las organizaciones dentro de la organización.
      </Paragraph>

      <Paragraph>
        2. Resguardar los intereses de nuestros clientes, incentivando las
        conductas éticas, la transparencia y las buenas prácticas comerciales.
      </Paragraph>

      <Paragraph>
        3. Supervisar los programas de prevención de delitos como: lavado de
        dinero, financiamiento del terrorismo y cohecho a empleados públicos.
      </Paragraph>

      <Paragraph>
        4. Fiscalizar el correcto cumplimiento de las políticas y procesos
        operacionales de la organización.
      </Paragraph>

      <Paragraph>
        5. Sancionar las faltas a las normas, regulaciones y políticas internas.
      </Paragraph>

      <Paragraph>
        6. Dentro de todas las actividades que realiza el departamento de
        Compliance, las más relevantes son:
      </Paragraph>

      <Paragraph>
        • Revisión periódica de las gestiones comerciales con clientes para
        fiscalizar el cumplimiento de las buenas prácticas comerciales.
      </Paragraph>

      <Paragraph>
        • Comité de Ética con directores de la organización para la resolución
        de faltas a las normas y reglamentos.
      </Paragraph>

      <Paragraph>
        • Revisión de operaciones internas de front office y back office.
      </Paragraph>

      <Paragraph>
        • Presentación de informes con evaluaciones de las plataformas
        comerciales.
      </Paragraph>

      <Paragraph>
        • Capacitaciones a cada ejecutivo comercial al menos una vez por año.
      </Paragraph>

      <Paragraph>
        • Fiscalización y evaluaciones de perfiles de riesgos de clientes.
      </Paragraph>

      <SubTitle>Buenas y Malas Prácticas</SubTitle>

      <Paragraph>
        Nos esmeramos en crecer éticamente cada día y en establecer relaciones
        de largo plazo con nuestros clientes. Fiscalizamos periódicamente el
        cumplimiento de las buenas prácticas comerciales con el objetivo de
        proteger a nuestros clientes y a la organización.
      </Paragraph>

      <Paragraph>
        <strong>Buenas Prácticas:</strong>
      </Paragraph>

      <Paragraph>
        • Que no prevalezca el interés personal de un ejecutivo por sobre el del
        cliente.
      </Paragraph>

      <Paragraph>
        • Recomendar siempre operar con StopLoss y TakeProfit para mitigar
        riesgo a nuestros clientes.
      </Paragraph>

      <Paragraph>
        • Comunicar elementos de análisis técnico en la descripción de una
        operación de trading.
      </Paragraph>

      <Paragraph>
        • Invitar a nuestros clientes a participar en los Webinars y seminarios
        para mejorar sus habilidades de trading y aumentar sus conocimientos
        sobre los productos ofrecidos por Capitaria.
      </Paragraph>

      <Paragraph>
        • Comunicar al cliente el riesgo asociado a cada operación, ya que no
        existen rentabilidades garantizadas.
      </Paragraph>

      <Paragraph>
        <strong>Malas Prácticas:</strong>
      </Paragraph>

      <Paragraph>
        • Garantizar o asegurar ganancias, rentabilidades o utilidades en
        instrumentos de renta variable y/o derivados.
      </Paragraph>

      <Paragraph>
        • Permitir que tu ejecutivo opere tu cuenta. Permitir que tu ejecutivo o
        analista te obligue o instruya de forma categórica a hacer una operación
      </Paragraph>

      <Paragraph>
        • Que no te expliquen los riesgos asociados a cada operación.
      </Paragraph>

      <Paragraph>
        • Enviar a nuestros ejecutivos o analistas incentivos.
      </Paragraph>

      <Paragraph>
        • Que tu ejecutivo o analista insista en posponer algún rescate
        solicitado por ti.
      </Paragraph>

      <AlertWrapper>
        <AlerBanner>
          En caso de detectar alguna de las malas prácticas descritas, o alguna
          otra que tú consideres que atenta contra la ética, transparencia o
          conducta responsable, te solicitamos que de forma urgente te
          comuniques con nosotros a través del canal de denuncias.
        </AlerBanner>
      </AlertWrapper>

      <SubTitle>Códigos de éticas</SubTitle>

      <Paragraph>
        Dejamos en manos de quien requiera nuestros códigos de ética corporativo
        y de conducta comercial que rigen a nuestra compañía
      </Paragraph>

      <Paragraph>
        <Anchor
          href="/codigo-de-etica-corporativo-v2020.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <EthicLinkText>Código de Ética Corporativo</EthicLinkText>
          <OpenFileIcon />
        </Anchor>
        <br />
        <Anchor
          href="/codigo-de-etica-y-conducta-comercial-2020.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <EthicLinkText>
            Código de Ética y Conducta Comercial Capitaria
          </EthicLinkText>
          <OpenFileIcon />
        </Anchor>
      </Paragraph>

      <SubTitle>Canal de denuncias Ley 20393</SubTitle>

      <Paragraph>
        Conforme a la Ley N°20.393, que establece la responsabilidad penal a las
        personas jurídicas por la comisión de los delitos de lavado de activos,
        financiamiento del terrorismo, cohecho a funcionario público nacional y
        extranjero, y delito de receptación, Capitaria coloca a disposición de
        sus clientes, proveedores, empleados y público en general, el canal para
        denunciar cualquier acto relativo a la comisión de dichos delitos.
      </Paragraph>

      <Paragraph>
        Para cualquier denuncia relativo a lo indicado anteriormente, por favor
        haga su denuncia a:
      </Paragraph>

      <Paragraph>
        <strong>Email: </strong>
        <MailTo href="mailto:compliance@capitaria.com">
          compliance@capitaria.com
        </MailTo>
        <br />
        <strong>Fono: </strong>
        <span>+56 2 2592 6620</span>
        <br />
        <Anchor
          href="/manual-de-prevencion-de-delitos-ley-20393-capitaria-v2022.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <EthicLinkText>
            Manual de Modelo de Prevención de Delitos Ley 20393
          </EthicLinkText>
          <OpenFileIcon />
        </Anchor>
      </Paragraph>

      <Paragraph>
        En su denuncia, debe proporcionar una descripción detallada de los
        hechos que está denunciando, agregar pruebas en caso de tenerlas (o
        comentar la existencia de ellas). Si lo desea (no es obligatorio), puede
        aportar con sus datos de contacto como Nombre completo, RUT, Dirección,
        Teléfono y Email de Contacto.
      </Paragraph>
    </Content>
  </Container>
)

export default Transparency
