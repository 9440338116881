import React from "react"
import styled from "@emotion/styled"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 323px;
  margin-top: 30px;
  @media (min-width: 1024px) {
    margin-top: 60px;
    max-width: 663px;
  }
  @media (min-width: 736px) and (max-width: 1024px) {
    margin-top: 60px;
    max-width: 500px;
  }
`

const Title = styled.h1`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 116%;
`

const Question = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
`

const Paragraph = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-size: 12px;
  line-height: 116%;
`

const PrivacyPolicies = () => (
  <Container>
    <Content>
      <Title>Políticas de privacidad</Title>

      <Question>¿Para qué solicitamos su información personal?</Question>

      <Paragraph>
        1. Para cumplir con las obligaciones respecto a las políticas Contra el
        Lavado de Dinero, para confirmar su identidad, el recurso de sus fondos,
        para categorizarlo como cliente y para administrar y gestionar los
        productos y servicios que brindamos, usted y cualquier información que
        sea requerida para asegurar y evidenciar nuestro cumplimiento con
        nuestras obligaciones legales y reglamentarias.
      </Paragraph>

      <Paragraph>
        2. Para tomar medidas antes de celebrar un contrato con usted y / o para
        el cumplimiento del contrato celebrado con usted para que podamos
        proporcionarle los productos y servicios que ha solicitado, incluido el
        procesamiento de transacciones, para la solución de problemas, para
        mantener y administrar su cuenta y su perfil personal, y para
        contactarle cuando sea necesario y / o apropiado en relación a los
        productos y servicios que se le proporcionan, incluso a través de
        terceros; para autenticaciones y / o controles de identidad, incluidos
        revisiones con agencias de referencia de crédito (que pueden mantener un
        registro de la información) para prevenir el fraude y para fines de
        administración de riesgos.
      </Paragraph>

      <Paragraph>
        3. Por nuestros intereses legítimos, como ayudar constantemente a
        mejorar nuestros servicios, incluida la detección, prevención y
        respuesta al fraude, abuso, riesgos de seguridad y problemas técnicos
        que pueden perjudicarnos a nosotros, a nuestros servicios y a nuestros
        clientes; para mantener nuestras Plataformas seguras y protegidas; para
        optimizar constantemente nuestros productos y servicios para los
        clientes existentes y potenciales para incluir, pero sin limitaciones,
        desarrollar una mejor comprensión de los productos y servicios que
        nuestros clientes y clientes potenciales pueden estar interesados en
        obtener y adaptar de acuerdo con ellos; para medir y comprender la
        efectividad de la publicidad y para ofrecer la publicidad más relevante
        a los clientes y otros; para mejorar nuestras plataformas y asegurar que
        el contenido se presente de la manera más efectiva; si corresponde, para
        permitir que los clientes participen en las funciones interactivas de
        nuestro servicio, cuando así lo deseen; para llevar a cabo encuestas y
        cuestionarios y para crear información agregada impersonalizada y / o
        estadística a este efecto.
      </Paragraph>

      <Paragraph>
        4. Con fines de marketing, para que podamos mantener a nuestros clientes
        actualizados en relación con asuntos tales como los contratos que
        nuestros clientes han negociado o comercializado, productos y servicios
        existentes y nuevos que nosotros estamos ofreciendo. El acceso a la
        Información Personal está restringido a los empleados, socios,
        contratistas, asesores, afiliados y proveedores de servicios. Quienes
        tengan acceso a la información personal están obligados a cumplir con
        estrictos estándares de confidencialidad y protección de la información
        personal y otra información. Capitaria invierte esfuerzos para mantener
        las salvaguardas adecuadas a fin de garantizar que la seguridad,
        integridad y privacidad de la información personal no se utilicen
        indebidamente. Tales medidas y medidas de seguridad incluyen el cifrado
        durante la transmisión de información, mecanismos de autenticación
        sólidos y la separación de máquinas e información para proporcionar
        áreas seguras. Si bien dichos sistemas y procedimientos reducen
        significativamente el riesgo de violaciones de seguridad y el uso
        inadecuado de la Información personal, no proporcionan una seguridad
        absoluta. Capitaria revisará regularmente sus medidas de seguridad para
        eliminar de manera efectiva la posibilidad de cualquier forma de mal uso
        cuando sea necesario.
      </Paragraph>

      <Paragraph>
        Si tiene alguna inquietud acerca de nuestro procesamiento de la
        Información Personal que no podemos resolver, siempre puede acercarse a
        nosotros o a la autoridad de protección de datos competente donde
        reside.
      </Paragraph>
    </Content>
  </Container>
)

export default PrivacyPolicies
