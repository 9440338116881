import React from "react"

import Layout from "../components/ui/layout"
import SEO from "../components/seo"
import LegalPage from "../components/legal-page/legal-page"
import { QueryStringProvider } from "@capitaria/capitaria-utms"

const Legal = () => (
  <QueryStringProvider>
    <Layout>
      <SEO title="Legal" />
      <LegalPage />
    </Layout>
  </QueryStringProvider>
)

export default Legal
