import React from "react"
import styled from "@emotion/styled"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 323px;
  margin-top: 30px;
  @media (min-width: 1024px) {
    margin-top: 60px;
    max-width: 663px;
  }
  @media (min-width: 736px) and (max-width: 1024px) {
    margin-top: 60px;
    max-width: 500px;
  }
`

const Title = styled.h1`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 116%;
`

const Paragraph = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-size: 12px;
  line-height: 116%;
`

const PrivacyPolicies = () => (
  <Container>
    <Content>
      <Title>Aviso Legal de Riesgo</Title>

      <Paragraph>
        1. Las transacciones en los mercados financieros con apalancamiento
        implican un alto nivel de riesgo. Esto significa que un movimiento
        relativamente pequeño en el mercado tendrá un impacto proporcionalmente
        mayor en los fondos que Ud. ha depositado o deberá depositar; este
        efecto puede ser tanto ventajoso como desventajoso para su cuenta. Usted
        podrá obtener una pérdida total de fondos y necesitar de fondos
        adicionales para mantener su posición en el negocio. Si en cualquier
        momento el patrimonio de su cuenta no es suficiente para garantizar sus
        posiciones en los mercados financieros podrá sufrir el cierre de las
        mismas.
      </Paragraph>

      <Paragraph>
        2. La utilización de ciertas órdenes (por ejemplo, orden de detención de
        pérdida) diseñadas para limitar pérdidas determinadas, pueden resultar
        inefectivas debido a las condiciones prevalecientes en el mercado; la
        ejecución de estas órdenes puede estar imposibilitada por el mercado.
        Las estrategias que se valen de combinaciones de posiciones pueden ser
        tan riesgosas como el uso de posiciones simples "cortas" o "largas".
      </Paragraph>

      <Paragraph>
        3. Suspensión o restricción de operaciones y relaciones de precios: Las
        condiciones de mercado y/o las reglas de operación de ciertos mercados
        (por ejemplo, la suspensión de transacciones en cualquier moneda ante
        límites de precio, intervención gubernamental, o "interruptores") pueden
        aumentar el riesgo de pérdida, dificultando o imposibilitando la
        realización de transacciones o liquidación de posiciones.
      </Paragraph>

      <Paragraph>
        4. Comisiones y otros gastos. Antes de iniciar sus operaciones,
        Capitaria entregará una explicación clara de todas las comisiones,
        honorarios, “spreads”, diferenciales de intereses (rollovers) y otros
        gastos que podrían presentarse, previa solicitud del cliente. Estos
        gastos afectarán su ganancia neta o aumentarán sus pérdidas.
      </Paragraph>

      <Paragraph>
        5. Antes de realizar transacciones en otras jurisdicciones, es
        recomendable que Ud. averigüe acerca de reglas relevantes aplicables a
        tales operaciones. Las autoridades reguladoras locales no están
        capacitadas para proteger sus transacciones en mercados ajenos a su
        jurisdicción, o para disuadir reglas externas a la propia autoridad
        local. Ud. deberá consultar los detalles acerca de las opciones de
        compensación disponibles en su jurisdicción local y en otras
        jurisdicciones relevantes antes de comenzar sus transacciones.
      </Paragraph>

      <Paragraph>
        6. Riesgos de moneda. Las ganancias y pérdidas en transacciones
        realizadas en denominaciones monetarias extranjeras – en jurisdicción
        propia o extranjera - estarán afectadas por fluctuaciones en el mercado
        cambiario cuando sea necesario convertir la denominación utilizada a
        otra distinta.
      </Paragraph>

      <Paragraph>
        7. Si bien los índices y precios están respaldados por muchos sistemas
        informáticos a nivel mundial, esto no garantiza la liquidez continua del
        mercado. Como todo sistema informático, estos componentes están sujetos
        a interrupciones o fallas temporales, lo cual eventualmente le podría
        impedir acceder a los mercados y realizar sus operaciones, por fallas
        propias del mercado o falta de liquidez del mismo. Dichas circunstancias
        se encuentran absolutamente fuera del alcance de Capitaria.
      </Paragraph>

      <Paragraph>
        8. Si usted decide realizar sus operaciones en un sistema electrónico se
        verá expuesto a riesgos asociados al sistema informático, incluyendo
        fallas de hardware y software. Como consecuencia, sus órdenes pueden ser
        procesadas erróneamente o no ejecutadas. Fallas de Internet y Sistema:
        Capitaria no controla la intensidad de señal, su recepción ni su curso
        vía Internet, la configuración de su sistema ni la confiabilidad de su
        conexión, no se responsabiliza por comunicaciones fallidas,
        distorsiones, ni retrasos cuando Ud. realice sus transacciones vía
        Internet.
      </Paragraph>

      <Paragraph>
        9. Asimismo, cualquier pérdida o ganancia fallida en la cuenta del
        cliente son responsabilidad del cliente y no de Capitaria, aun en casos
        en los cuales fallos o errores de sistema, hardware o software hayan
        contribuido a tales pérdidas o ganancias fallidas. En caso de
        verificarse estos errores, el cliente conoce que ha otorgado mandato
        expreso a Capitaria para que procedan a la anulación de dichas
        operaciones.
      </Paragraph>

      <Paragraph>
        10. Operar en los mercados financieros no es conveniente para todas las
        personas, se recomienda expresamente invertir sólo con fondos que
        representen excedentes y que puedan ser utilizados como capital de
        riesgo.
      </Paragraph>

      <Paragraph>
        11. El cliente está obligado a mantener sus contraseñas protegidas y
        secretas para asegurar que terceros no accedan a su cuenta. Las empresas
        no tendrán responsabilidad alguna en caso del uso indebido de la
        contraseña, aún en casos de uso circunstancial.
      </Paragraph>

      <Paragraph>
        12. Los precios son entregados por distintos proveedores, no por
        Capitaria, por tanto, en caso de existir errores en ellos, serán de su
        exclusiva responsabilidad.
      </Paragraph>

      <Paragraph>
        13. El cliente ha sido informado que las operaciones siempre deben
        efectuarse a precio de mercado, por tanto, si en virtud de un error en
        la colocación de los precios por parte de los proveedores o por otras
        circunstancias ajenas al control de Capitaria, se generan ganancias o
        pérdidas, Capitaria está facultado para reversar dichas operaciones.
      </Paragraph>

      <Paragraph>
        14. El cliente debe revisar periódicamente en la página web
        www.capitaria.com la política de márgenes, horarios, costo de rollovers,
        instrumentos disponibles, comisiones y gastos establecidos para operar
        con las Capitaria.
      </Paragraph>
    </Content>
  </Container>
)

export default PrivacyPolicies
